/* eslint-disable react-hooks/exhaustive-deps */
import { lazy, useState, useEffect } from "react";
import { FlexRow, SLNotification } from "../../components/core/Utility";
import { GridComponent } from "../../components/core/Table/GridComponent";
import { Loader } from "@vds/loaders";
import { Title } from "@vds/typography";
import { VIEW_EVENTS_UNITS_COL } from "../../constants/UploadUnitsConstants";
import { useToGetEvents } from "../../services/Aggregator/batchHistory";
import { generate } from "shortid";
import isEmpty from "lodash/isEmpty";
import styled from "@emotion/styled";

const RowSpan = styled.span`
  text-wrap: auto;
  padding: 10px 0px;
`;
const ViewEvents = () => {
  const [batchHistoryArr, setBatchHistoryArr] = useState([]);
  const [notification, setNotification] = useState();

  const notify = (content, type) => {
    setNotification(<SLNotification key={generate()} type={type} title={content} fullBleed={true} />);
  };
  const { data, error, isLoading, fetchEvents } = useToGetEvents();

  useEffect(() => {
    if (!isLoading && !isEmpty(data)) {
      if (isEmpty(error)) {
        setBatchHistoryArr(data);
      } else if (!isEmpty(error)) {
        notify(error, "error");
      }
    }
  }, [data, error, isLoading]);

  const formattedRows = (data) =>
    data.map(({ createdAt, status, errorMessage, ...rest }) => ({
      ...rest,
      status,
      createdAt: new Date(createdAt),
      errorMessage: status?.toLowerCase() === "completed" ? "Units uploaded successfully" : errorMessage,
    }));

  useEffect(() => {
    fetchEvents();
  }, []);

  const formattedTD = (rowData, dataField) => <RowSpan>{rowData[dataField.field]}</RowSpan>;

  const MODIFY_VIEW_EVENTS_UNITS_COL = VIEW_EVENTS_UNITS_COL.map((col) => {
    return col.value === "errorMessage" || col.value === "esn" ? { ...col, customBodyCallback: formattedTD } : col;
  });

  return (
    <>
      <FlexRow justifyContent="space-between" padding="10px 0">
        <Title primitive="p" size="medium">
          View Events
        </Title>
        <FlexRow
          css={{ justifyContent: "center", alignItems: "center", backgroundColor: "gray", width: "35px", height: "35px", borderRadius: "5px" }}
          onClick={fetchEvents}
        >
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.7875 2.20312C11.4281 0.84375 9.5625 0 7.49062 0C3.34687 0 0 3.35625 0 7.5C0 11.6438 3.34687 15 7.49062 15C10.9875 15 13.9031 12.6094 14.7375 9.375H12.7875C12.0187 11.5594 9.9375 13.125 7.49062 13.125C4.3875 13.125 1.86562 10.6031 1.86562 7.5C1.86562 4.39688 4.3875 1.875 7.49062 1.875C9.04687 1.875 10.4344 2.52188 11.4469 3.54375L8.42812 6.5625H14.9906V0L12.7875 2.20312Z"
              fill="white"
            />
          </svg>
        </FlexRow>
      </FlexRow>
      <Loader active={isLoading} />
      {notification}
      <GridComponent
        isSelectionEnabled={false}
        isExportEnabled={false}
        noDataMessage="No records found"
        rows={formattedRows(batchHistoryArr || [])}
        columns={MODIFY_VIEW_EVENTS_UNITS_COL}
        title="View Events"
      />
    </>
  );
};

export default ViewEvents;

export const ViewEventsId = {
  name: "View Upload Unit Events",
  id: "viewevents",
  component: lazy(() => import("./ViewEvents")),
  route: "/viewevents",
};
