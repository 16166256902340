import React, { lazy, useEffect, useState, useContext, useMemo } from "react";
import { GridComponent } from "../../components/core/Table/GridComponent";
import { WORK_VIEW_ITEM_TABLE_COL } from "../../constants/WorkViewItemTableConstants";
import { Header } from "../../components/core/Controls";
import { Accordion, AccordionTab } from "primereact/accordion";
import styled from "styled-components";
import { Grid, Row } from "@vds/grids";
import { CompactCol, CustomSLButton, FlexRow, SmallText, SLGridLayout, SLRowLayout, SLNotification } from "../../components/core/utility";
import { css } from "@emotion/react";
import { DropdownSelect } from "@vds/selects";
import { UltraMediumText, Spacer } from "../../components/core/Utility/index";
import InProgressImg from "../../assets/icons/InProgress.svg";
import ErrorImg from "../../assets/icons/Error.svg";
import ReleasedImg from "../../assets/icons/Released.svg";
import CancelImg from "../../assets/icons/Cancelled.svg";
import ReturnSubmittedImg from "../../assets/icons/ReturnSubmitted.svg";
import moment from "moment";
import { RETURN_TABLE_COL } from "../../constants/ReturnTableConstants";
import { ReturnTableFlyOutMenu } from "./ReturnTableFlyOutMenu";
import ReturnTicketModal from "./ReturnTicketModal";
import MMIDFlyOutView from "./MMIDFlyOutView";
import { InputTextarea } from "primereact/inputtextarea";
import { WORK_VIEW_VEHICLE_TABLE_COL } from "../../constants/ViewWorkTicketConstants";
import { C4SContext } from "../../context/c4sContext";
import DocumentFlowTable from "./DocumentFlowTable";
import { Line } from "@vds/lines";
import { Dropdown } from "primereact/dropdown";
import "./dropdown.css";
import { getFullName, redirectToPrevPage, switchTab, TYPE_DROPDOWN_VALUES } from "./utils";
import { OrderTrackingInfoTable } from "./OrderTrackingTable";
import { CustomInput } from "./AddAVehicle";
import {
  DISTRIBUTION_CHANNEL_DROPDOWN_VALUES,
  DIVISION_DROPDOWN_VALUES,
  ERP_STATUS_DROPDOWN_VALUES,
  ERP_SUB_STATUS_DROPDOWN_VALUES,
} from "../../constants/C4SDecomConstants";

import ConfirmDeliveryDateModal from "./ConfirmDeliveryDateModal";
import { PartialShipmentLostModal } from "./PartialShipmentLostModal";
import _ from "lodash";
import { Loader } from "@vds/loaders";
import { getReturnTickets } from "../../services/C4S Decommission - Replacement/getReturnTickets";
import { addReturnItem } from "../../services/C4S Decommission - Replacement/addReturnItem";

import { generate } from "shortid";
import { getCSPDetails } from "../../services/C4S Decommission - Replacement/getCSPDetails";
import { updateNewrelicStats } from "../../../utility/newrelic";
import { updateReturnItem } from "../../services/C4S Decommission - Replacement/updateReturnItem";
import { deleteReturnItem } from "../../services/C4S Decommission - Replacement/deleteReturnItem";
import { Modal, ModalBody, ModalFooter, ModalTitle } from "@vds/modals";
import Icon from "@vds/icons";
import { Title } from "@vds/typography";
import { getVinMaterialList } from "../../services/C4S Decommission - Replacement/getVinMaterialList";
import { submitReturnItem } from "../../services/C4S Decommission - Replacement/submitReturnItem";
import { addLostShipment } from "../../services/C4S Decommission - Replacement/addLostShipment";
import { workticketUpdate } from "../../services/C4S Decommission - Replacement/workticketUpdate ";
import MigratedSchedulingData from "./MigratedSchedulingData";
import { hasPrivilege } from "../../../utility/privileges";
import { Privileges } from "../../../constants/PrivilegeConstants";
import { getERPStatusList } from "../../services/scheduler/getERPStatusList";
import { ValidateAddress } from "./ValidateAddress";
import useOutsideClick from "../../customHook/use-outside-click";

const TextArea = styled(InputTextarea)`
  left: 0px !important;
  border: 1px solid #b7b8b8 !important;
  border-radius: 4px !important;
  margin-top: 0px !important;
  height: 88px !important;
  // width: -webkit-fill-available !important;
  font-family: Verizon-NHG-eDS !important;
  font-size: 16px !important;
  font-weight: 400 !important;
`;

export const CustomisedInput = styled(Dropdown)`
  border: 0.0625rem solid #d8dada !important;
  border-bottom: 0.0625rem solid #000000 !important;
  width: 90%;
  height: 2.75rem;
  box-shadow: none !important;
  border-radius: 0;
  .p-inputtext,
  .p-button,
  .p-button:enabled:hover,
  .p-button:enabled:active,
  .p-dropdown:focus,
  .p-dropdown:active {
    font-size: 12px;
    font-family: Verizon-NHG-eTX;
    height: auto;
    margin: auto;
    position: relative;
    border: none;
    color: #000000 !important;
    background-color: transparent;
  }
`;

const SpaceNote = styled.div`
  @media screen and (min-width: 1025px) {
    padding: 8px;
  }
`;

export const StyledCol = css`
  & div {
    @media screen and (min-width: 1260px) {
      // width: 99.5%;
      padding-right: 0.15em;
    }
  }
`;

export const CustomDropdown = styled(DropdownSelect)`
  & div {
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "")};
    padding-right: ${(props) => (props.paddingRight ? props.paddingRight : "")};
    & label {
      & p {
        font-size: 12px;
        color: ${(props) => (props.disabled ? "#d8dada" : "")};
        & span {
          &::after {
            color: #747676;
          }
        }
      }
    }
  }
  }
  & select {
    font-size: 12px;
    color: ${(props) => (props.disabled || props.disableValue ? "#666b6b" : "")};
    &:disabled {
      -webkit-text-fill-color: #000000;
      background-color: #ffffff;
      border-bottom: 1px solid #000000;
      // color: ${(props) => (props.disabled || props.disableValue ? "#d8dada" : "")};
      opacity: ${(props) => (props.disabled ? "0.4" : "")};
    }
  }
`;

const Label = styled.label`
  font-family: "Verizon-NHG-eTX";
  font-size: 12px;
  margin-top: 10px;
  color: #000000;
`;

export const AccordionWrapper = styled.div`
  padding-top: 2.5rem;
  .p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link,
  .p-accordion .p-accordion-tab .p-accordion-header:hover .p-accordion-header-link,
  .p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
    color: #000000;
    border: none;
    padding: 0.5rem;
    background: #f6f6f6;
    box-shadow: unset;
    border-radius: unset;
  }

  .p-accordion .p-accordion-content {
    padding: 0rem;
    border: none;
  }
  .p-accordion-header-text {
    font-weight: 700;
    font-family: Verizon-NHG-eDS;
  }
`;
const TableWrapper = styled.div`
  position: relative;
  overflow-x: auto;
  width: 94%;
`;
const GridComponentWrapper = styled.div`
  .p-datatable-scrollable .p-datatable-wrapper {
    overflow-y: auto;
    ::-webkit-scrollbar {
      display: block !important;
      width: 5px;
      height: 5px;
    }
    ::-webkit-scrollbar-thumb {
      display: inline-block !important;
      background-color: #c0c0c0 !important;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1 !important;
    }
  }
`;
const OverviewColumn = styled.div`
  width: 24%;
  padding-right: 6px;
`;

export default function WorkViewItemTable(props) {
  const [workTicketData, setWorkTicketData] = useState([]);
  // const returnTicketListdata = [];
  const [returnTicketList, setReturnTicketList] = useState([]);
  const [returnTicketError, setReturnTicketError] = useState(null);
  const [returnAccordianClickCount, setReturnAccordianClickCount] = useState(0);
  const [returnTicketModalData, setReturnTicketModalData] = useState({
    isShown: false,
    workTicketData: [],
    index: 0,
    type: "",
  });
  const [documentFlowData, setDocumentFlowData] = useState();
  let partialQtyMappedMMIDs = [];
  const [OneERPStatus, setOneERPStatus] = useState([]);
  const [OneERPSubStatus, setOneERPSubStatus] = useState([]);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
  const [individualReturn, setIndividualReturn] = useState(false);
  const [migratedWT, setMigratedWT] = useState(props.data?.migratedRecord);
  const [erpStatusAccordianClickCount, setERPStatusAccordianClickCount] = useState(0);
  const [erpStatusAccordianError, setERPStatusAccordianError] = useState(null);
  const WTAddress =
    props?.data?.addressOne?.length > 0 ||
    props?.data?.addressTwo?.length > 0 ||
    props?.data?.city?.length > 0 ||
    props?.data?.stateCode?.length > 0 ||
    props?.data?.country?.length > 0 ||
    props?.data?.zipCode?.length > 0;
  const { isSourceCSP } = useContext(C4SContext);
  let cspCaseData = JSON.parse(sessionStorage.getItem("CSPDetails")),
    cspCaseOwner = "",
    crmReferenceId = "";
  let cspCaseDetails = {
    assignedTo: null,
    assignedToEmail: null,
    assignedToEid: null,
  };
  if (isSourceCSP.isSourceCSP) {
    cspCaseOwner = cspCaseData.CSP?.filter((cspData) => cspData.DefaultCSP === "1")[0]?.CSPOwner ?? "";
    crmReferenceId = cspCaseData.CSP?.filter((cspData) => cspData.DefaultCSP === "1")[0]?.CRM_Ref_ID ?? "";
    let cspData = cspCaseData.CSP.length ? cspCaseData.CSP.find((item) => item.DefaultCSP === "1") : {};
    cspCaseDetails = {
      assignedTo: cspData?.CSPOwner || null,
      assignedToEmail: cspData?.CSPOwnerEmail || null,
      assignedToEid: cspData?.CSPOwnerEId || null,
    };
  } else {
    cspCaseOwner = cspCaseData.CASEDetails?.filter((caseData) => caseData.DefaultCase === "1")[0]?.CaseOwner ?? "";
    crmReferenceId = cspCaseData.CASEDetails?.filter((caseData) => caseData.DefaultCase === "1")[0]?.CaseNumber ?? "";
    let caseData = cspCaseData.CASEDetails.length ? cspCaseData.CASEDetails.find((item) => item.DefaultCase === "1") : {};
    cspCaseDetails = {
      assignedTo: caseData?.CaseOwner || null,
      assignedToEmail: caseData?.CaseOwnerEmail || null,
      assignedToEid: caseData?.CaseOwnerEId || null,
    };
  }
  const [address, setAddress] = useState({
    verified: false,
    addressByPass: null,
    locationFound: null,
    timezone: "",
    historicalAddress: { value: "", error: false, errorText: "", success: false },
    address1: { value: "", error: false, errorText: "Enter valid street address", success: false },
    address2: { value: "", error: false, errorText: "Enter address 2", success: false },
    city: { value: "", error: false, errorText: "Enter city", success: false },
    country: { value: null, options: [], error: false, errorText: "Please select country", success: false },
    state: { value: null, options: [], allStateOptions: null, error: false, errorText: "Please select state", success: false },
    zip: { value: "", error: false, errorText: "Enter zip code", success: false },
  });
  const handleClickOutside = () => {
    props.setNotification(null);
    setReturnTicketError(null);
    setERPStatusAccordianError(null);
  };

  const clickRef = useOutsideClick(handleClickOutside);
  const materialLineIdGenerator = function* (lineId = "000000") {
    while (true) {
      lineId = ("0000" + (+lineId + 10)).slice(-6);
      yield lineId;
    }
  };

  let limitWT = sessionStorage.getItem("limitWTCreation") == "true" ? true : false;
  let ERPStatus = props.data?.erpStatus;
  let ERPSubStatus = ERP_SUB_STATUS_DROPDOWN_VALUES.filter((record) => record.code === props.data?.subStatus)[0]?.description;
  const [selectedAssignedTo, setAssignedTo] = useState({ name: cspCaseOwner });
  const selectAssignedTo = [cspCaseOwner ? { name: cspCaseOwner } : ""];
  const [orderTrackingData, setOrderTrackingData] = useState();
  const [selectedERPStatus, setSelectedERPStatus] = useState(ERPStatus ? ERPStatus : "");
  const [selectedERPSubStatus, setSelectedERPSubStatus] = useState(props.data?.subStatus ? ERPSubStatus : "");
  const [originalDONumber, setOriginalDONumber] = useState({ value: "", error: true, errorText: "Enter Original Delivery Number" });
  const [transitModal, setTransitModal] = useState(false);
  const [partialShipmentLostModal, setPartialShipmentLostModal] = useState(false);
  const [partialTransitLostMMID, setPartialTransitLostMMID] = useState(null);
  const FULL_SHIPMENT_LOST = "Full Shipment - Lost in Transit";
  const PARTIAL_SHIPMENT_LOST = "Partial Shipment - Lost in Transit";
  const [isLoading, setLoader] = useState(false);
  // const [notification, setNotification] = useState(null);
  const [userName, setUsername] = useState("");
  const [vehicleInfoList, setVehicleInfoList] = useState([]);
  const [returnDeleteConfirmModal, setReturnDeleteConfirmModal] = useState(false);
  const [returnDeleteData, setReturnDeleteData] = useState(null);
  const [rtnReson, setReson] = useState(null);
  const [esitEsn, setEsitEsn] = useState([]);
  const [retnQty, setRetnQty] = useState(null);
  const [editReturnMaterialId, setEditReturnMaterialId] = useState(null);
  const [editReturnMaterialProcessingType, setEditReturnMaterialProcessingType] = useState(null);
  const [wareHouseNote, setWareHouseNote] = useState(props.data?.wareHouseNotes ? props.data?.wareHouseNotes : "");
  const [esnNote, setESNNote] = useState(props.data?.esnNotes ? props.data?.esnNotes : "");
  const [mmidDNNumber, setMMIDDNNumber] = useState([]);
  const [lineIdGenerator, setLineIdGenerator] = useState(materialLineIdGenerator);
  const [cspLoader, setCspLoader] = useState(false);
  const [returnTicketLoader, setReturnTicketLoader] = useState(false);
  const filteredWTMaterialList = props?.data?.materials?.filter((item) => item?.materialId?.startsWith("11"));
  const [defaultReturnType, setDefaultReturnType] = useState(null);
  const [editReturnData, setEditReturnData] = useState(null);

  let shippingAddress = null;
  shippingAddress = [props?.data?.addressOne, props?.data?.addressTwo, props?.data?.cityState, props?.data?.zipCode].filter((val) => val).join(", ");

  const [expandedRow, setExpandedRow] = useState([]);

  const TYPE_DROPDOWN = [
    { description: "Hardware accessory shipment", code: "ZHWA" },
    { description: "Mileage", code: "ZTML" },
    { description: "Customer Fee", code: "ZBTP" },
    { description: "Service-Misc", code: "ZSP1" },
    { description: "Service", code: "ZSPO" },
    { description: "Hardware shipment exchange", code: "ZZHS" },
    { description: "Hardware shipment", code: "ZHWS" },
    { description: "Hardware misc", code: "ZHS1" },
    { description: "Return Hardware", code: "ZZHR" },
    { description: "Return Accessories", code: "ZHAR" },
    { description: "Accessory Shipment Exchange", code: "ZZAE" },
  ];

  useEffect(() => {
    let initialCheck = !(limitWT || hasPrivilege(Privileges.ENABLE_WORKTICKET_PORTAL_READONLY));
    let fullShipment = selectedERPSubStatus === FULL_SHIPMENT_LOST;
    let fullLITCheck =
      selectedERPSubStatus === FULL_SHIPMENT_LOST &&
      originalDONumber?.value?.length > 0 &&
      mmidDNNumber.length > 0 &&
      mmidDNNumber.includes(originalDONumber?.value);
    let partialShipment = selectedERPSubStatus === PARTIAL_SHIPMENT_LOST;
    let addressFilled =
      address.address1.value?.length > 0 ||
      address.address2.value?.length > 0 ||
      address.city.value?.length > 0 ||
      address.zip.value?.length > 0 ||
      address.state.value?.length > 0 ||
      address.country.value?.code?.length > 0;
    let previousValueCheck =
      (selectedERPStatus ? selectedERPStatus === ERPStatus : true) &&
      (selectedERPSubStatus ? selectedERPSubStatus === ERPSubStatus : true) &&
      (wareHouseNote ? wareHouseNote === props.data?.wareHouseNotes : true) &&
      (esnNote ? esnNote === props.data?.esnNotes : true);
    if (initialCheck) {
      if (fullShipment || partialShipment) {
        if (fullShipment) {
          fullLITCheck
            ? filteredWTMaterialList?.length > 0 && vehicleInfoList?.length > 0
              ? migratedWT
                ? !WTAddress
                  ? address.verified
                    ? setSaveBtnDisabled(false)
                    : setSaveBtnDisabled(true)
                  : setSaveBtnDisabled(false)
                : setSaveBtnDisabled(false)
              : setSaveBtnDisabled(true)
            : setSaveBtnDisabled(true);
        }
        if (partialShipment) {
          filteredWTMaterialList?.length > 0 && vehicleInfoList?.length > 0
            ? migratedWT
              ? !WTAddress
                ? address.verified
                  ? setSaveBtnDisabled(false)
                  : setSaveBtnDisabled(true)
                : setSaveBtnDisabled(false)
              : setSaveBtnDisabled(false)
            : setSaveBtnDisabled(true);
          // if (migratedWT) {
          //   !WTAddress ? (address.verified ? setSaveBtnDisabled(false) : setSaveBtnDisabled(true)) : setSaveBtnDisabled(false);
          // } else {
          //   setSaveBtnDisabled(false);
          // }
          // migratedWT ? (address.verified ? setSaveBtnDisabled(false) : setSaveBtnDisabled(true)) : setSaveBtnDisabled(false);
        }
      } else {
        if (migratedWT) {
          !WTAddress
            ? addressFilled
              ? address.verified
                ? setSaveBtnDisabled(false)
                : setSaveBtnDisabled(true)
              : setSaveBtnDisabled(false)
            : setSaveBtnDisabled(false);
        } else {
          setSaveBtnDisabled(false);
        }
      }
    } else {
      setSaveBtnDisabled(true);
    }
    previousValueCheck && setSaveBtnDisabled(true);
  }, [
    selectedERPSubStatus,
    mmidDNNumber,
    originalDONumber?.value,
    address.verified,
    address.address1.value,
    address.address2.value,
    address.city.value,
    address.zip.value,
    address.state.value,
    address.country.value,
    selectedERPStatus,
    ERPStatus,
    ERPSubStatus,
    wareHouseNote,
    esnNote,
    vehicleInfoList,
  ]);

  const getERPStatusData = async () => {
    setLoader(true);
    setERPStatusAccordianClickCount(1);
    let response = await getERPStatusList();
    if (response.status === 200 && response?.data?.data.length) {
      const erpStatusList = response.data.data.filter((state) => state.type === "WORKTICKET_MANUAL_STATUS");
      const erpSubStatusList = response.data.data.filter((state) => state.type === "WORKTICKET_MANUAL_SUB_STATUS");
      setOneERPStatus(erpStatusList);
      setOneERPSubStatus(erpSubStatusList);
    } else {
      setERPStatusAccordianError("Unable to fetch data due to an unexpected error");
      setTimeout(() => {
        props.setNotification(null);
      }, 5000);
    }
    setLoader(false);
  };

  useEffect(() => {
    setVehicleInfoList(props?.data?.vins);
    let deliveryDocNumber = [];
    let workTicketListData = props?.data?.materials?.map((material) => {
      if (material.deliveryDocNumber) {
        deliveryDocNumber.push(material.deliveryDocNumber);
      }
      return {
        id: material.id,
        MMID: material.materialId,
        itemDescription: material.materialName,
        type: material.processingCode,
        displayType: getFullName(TYPE_DROPDOWN, material.processingCode),
        contractItem: material.contractName,
        quantity: material.totalQty,
        MmidPgiDate: material.materialId + " - " + props?.data?.pgiDate,
        esns: material.esn,
        trackingNumbers: material.trackingNumbers,
        trackingUrls: material.trackingUrls,
        deliveryBlock: material.deliveryBlock,
        carriers: material.carriers,
        pgiDate: material.pgiDate,
        pgiQty: material.pgiQty,
        salesOrder: material.salesOrder,
        x_purchaseOrder: material.x_purchaseOrder,
        deliveryDocNumber: material.deliveryDocNumber,
        wtMaterialId: material.mid,
        contractId: material.cid,
        sapId: material.sapId,
        techRefId: material.techRefId,
        bundleId: material.bundleId,
        defaultReturnType: material.defaultReturnType,
        customerMaterialCharge: material.customerMaterialCharge,
        materialChargeCurrency: material.materialChargeCurrency,
        totalQty: material.totalQty,
      };
    });
    let deliveryDocNumberRecord = [...new Set(deliveryDocNumber.filter((record) => record))];
    deliveryDocNumberRecord.length
      ? setOriginalDONumber({ value: deliveryDocNumberRecord[0], error: false, errorText: "" })
      : setOriginalDONumber({ value: "", error: true, errorText: "Enter Original Delivery Number" });
    setMMIDDNNumber(deliveryDocNumberRecord);
    setWorkTicketData(workTicketListData);
    let documentData = {
      workTicketNumber: [1],
      salesOrder: [1],
      deliveryNumber: [0],
      purchaseOrder: [1],
      returnDelivery: [1],
    };
    setDocumentFlowData(documentData);
    // getReturnTicketList();
    if (
      sessionStorage &&
      JSON.parse(sessionStorage.getItem("Name")) === null &&
      (JSON.parse(sessionStorage.getItem("FirstName")) === null || JSON.parse(sessionStorage.getItem("LastName")) === null)
    ) {
      getCSpDetails();
    } else {
      let user =
        sessionStorage &&
        (JSON.parse(sessionStorage.getItem("Name")) ??
          JSON.parse(sessionStorage.getItem("FirstName")) + " " + JSON.parse(sessionStorage.getItem("LastName")));
      setUsername(user);
    }
    if (!isSourceCSP.isSourceCSP && workTicketData?.length) {
      const isIndividualReturn = workTicketData.some((ind) => ind.type === "ZZHR" || ind.type === "ZHAR");
      setIndividualReturn(isIndividualReturn);
    }
  }, [props]);

  useEffect(() => {
    let returnTicketWithCreatedStatus = returnTicketList.find((returnTicket) => returnTicket?.status?.toLowerCase() === "return_created");
    let lineIdGenerator;
    if (returnTicketWithCreatedStatus) {
      let initialMaterialLineId =
        returnTicketWithCreatedStatus.returnMaterial.length > 1
          ? returnTicketWithCreatedStatus.returnMaterial.reduce((e1, e2) =>
              e1.materialLineId > e2.materialLineId ? e1.materialLineId : e2.materialLineId
            )
          : returnTicketWithCreatedStatus.returnMaterial[0].materialLineId;
      lineIdGenerator = materialLineIdGenerator(initialMaterialLineId);
    } else {
      lineIdGenerator = materialLineIdGenerator();
    }
    setLineIdGenerator(lineIdGenerator);
  }, [returnTicketList]);

  useEffect(() => {
    setLoader(cspLoader || returnTicketLoader);
  }, [cspLoader, returnTicketLoader]);

  const getCSpDetails = async () => {
    setCspLoader(true);
    updateNewrelicStats();
    let cspData = await getCSPDetails(props?.AccountID, props?.ID, props?.userId);
    if (cspData.status === 200 && cspData?.data?.data) {
      let data = cspData.data.data;
      let user = `${data.firstName} ${data.lastName}`;
      sessionStorage.setItem("FirstName", JSON.stringify(data.firstName));
      sessionStorage.setItem("LastName", JSON.stringify(data.lastName));
      setUsername(user);
    } else {
      notify("Error fetching csp details", "error");
    }
    setCspLoader(false);
  };

  const getReturnTicketList = async () => {
    setReturnTicketLoader(true);
    setReturnAccordianClickCount(1);
    let workTicketId = props.data.ticketId;
    let response = await getReturnTickets(workTicketId);
    if (response?.status === 200 && response?.data) {
      let returnTickets = response.data.data;
      let returnTicketWithCreatedStatus = returnTickets.filter((returnTicket) => returnTicket?.status?.toLowerCase() === "return_created");
      setReturnTicketList(returnTickets);
      setExpandedRow(returnTicketWithCreatedStatus);
    } else {
      setReturnTicketError("Error while fetching the return tickets");
    }
    setReturnTicketLoader(false);
  };

  const notify = (content, type) => {
    props.setNotification(<SLNotification key={generate()} type={type} title={content} fullBleed={true} />);
  };

  const addReturnTicket = async (data) => {
    let materialLineId = lineIdGenerator.next().value;
    let returnTicketWithCreatedStatus = returnTicketList.find((returnTicket) => returnTicket?.status?.toLowerCase() === "return_created");
    if (materialLineId === "" || materialLineId === undefined || materialLineId === null) {
      setReturnTicketModalData((prevData) => ({ ...prevData, isShown: false }));
      return setTimeout(() => {
        notify(
          "Unable to add return item due to missing information. Kindly contact IT team for support. Missing Information: Material Line Id",
          "error"
        );
      }, 10);
    }
    setLoader(true);
    let payload = {
      returnId: returnTicketWithCreatedStatus?.returnId ?? null,
      workticketId: props.data.ticketId,
      materialId: data.wtMaterialId,
      status: returnTicketWithCreatedStatus?.status ?? null,
      returnQty: data.quantity,
      esn: data.esns,
      returnReason: data.returnReason,
      processingType: data.processingType,
      materialLineId: materialLineId,
      sapId: data.sapId ? data.sapId : null,
      techRefId: data.techRefId,
      contractId: data.contractId ? data.contractId : null,
      createdBy: userName,
      createdByEmail: cspCaseData?.email,
      createdByEid: cspCaseData?.Employee_ID,
      modifiedBy: null,
      modifiedByEmail: null,
      modifiedByEid: null,
      assignedTo: cspCaseDetails.assignedTo,
      assignedToEmail: cspCaseDetails.assignedToEmail,
      assignedToEid: cspCaseDetails.assignedToEid,
      contractName: data.contractName?.length > 0 ? data.contractName : null,
      parentWMID: data?.parentWMID,
    };
    let response = await addReturnItem(payload);
    if (response?.status === 200 && response?.data) {
      // show success message
      notify("Return item added successfully", "success");
      setTimeout(() => {
        props.setNotification(null);
      }, 5000);
      await getReturnTicketList();
    } else {
      //show error
      notify("Unable to add the return item", "error");
      let newGenerator = materialLineIdGenerator(+materialLineId - 10);
      setLineIdGenerator(newGenerator);
    }
    setReturnTicketModalData((prevData) => ({ ...prevData, isShown: false }));
    setLoader(false);
  };

  const returnTableRowExpansionTemplate = (rowData) => (
    <ReturnTableFlyOutMenu rowData={rowData} handleReturnTicketModal={handleReturnTicketModal} getReturnDeleteData={getReturnDeleteData} />
  );

  const handleReturnTicketModal = (type, data, rowData) => {
    let dataToSend = [],
      dataIndex = 0;
    setEditReturnData(data);

    if (type !== "add") {
      let selectedSapId = data.sapId || null;
      let selectedTechRefId = data.techRefId || null;
      dataIndex = workTicketData.findIndex(
        (workTicket) => workTicket.MMID === data.mmid && workTicket.sapId === selectedSapId && workTicket.techRefId === selectedTechRefId
      );
      dataIndex = dataIndex > 0 ? dataIndex : 0;
    }

    if (type === "edit") {
      setReson(data.returnReason);
      if (data?.esn !== null && data?.esn !== "") setEsitEsn(data?.esn.split(","));
      setRetnQty(data.returnQty);
      setEditReturnMaterialId(data.returnMaterialId);
      setEditReturnMaterialProcessingType(data.processingType);
    }
    if (type === "add") {
      let returnType = props?.data?.materials.filter((material) => material.MMID === data.mmid)[0]?.defaultReturnType ?? "";
      setDefaultReturnType(returnType ? returnType : "");
    }

    dataToSend = workTicketData.map((data) => ({ ...data, returnId: rowData?.returnId }));
    setReturnTicketModalData((prevData) => ({ ...prevData, isShown: true, workTicketData: dataToSend, index: dataIndex, type: type }));
  };

  useEffect(() => {
    let orderTrackingInfo = {
      plannedQuantity: [1],
      pgiDate: [1],
      selfInstalled: [0],
      installed: [1],
      returnedOrder: [1],
    };
    setOrderTrackingData(orderTrackingInfo);
  }, []);

  const handleSave = () => {
    selectedERPSubStatus === FULL_SHIPMENT_LOST && originalDONumber?.value?.length && setTransitModal(true);
    selectedERPSubStatus === PARTIAL_SHIPMENT_LOST && setPartialShipmentLostModal(true);
  };

  const getMMIDData = (materialsArr, addLostShipment = false) => {
    let isPartialTransit = selectedERPSubStatus === PARTIAL_SHIPMENT_LOST ? true : false;
    if (materialsArr.length) {
      if (isPartialTransit) {
        let filteredMaterialsArr = _.cloneDeep(materialsArr).filter((material) =>
          [...partialTransitLostMMID.keys()].includes(`${material.materialId}${material.techRefId}`)
        );
        let result = filteredMaterialsArr
          .map((material) => {
            let mappedValue = partialTransitLostMMID.get(`${material.materialId}${material.techRefId}`.toString());
            let materialItem = {
              id: material.id,
              materialId: material.materialId,
              materialName: material.materialName,
              techRefId: material.techRefId,
              type: material.type,
              orderQty:
                partialQtyMappedMMIDs.includes(`${material.materialId}${material.techRefId}`) && addLostShipment == false ? 0 : mappedValue.qtyLost,
              esnToBeReturned: mappedValue?.esnList.map((val) => val).join(", "),
              bundleId: material.bundleId,
              featureId: material.featureId,
              sapId: material.sapId,
              sapValue: material.sapValue,
              sapName: material.sapName,
              orderType: material.orderType,
              upsell: material.upsell,
              chargeAmt: material.chargeAmt,
              currency: material.currency,
            };
            partialQtyMappedMMIDs.push(`${material.materialId}${material.techRefId}`);
            return materialItem;
          })
          .filter((material) => material?.orderQty > 0);
        return result;
      } else {
        if (migratedWT ? !materialsArr.every((mmid) => partialQtyMappedMMIDs.includes(`${mmid.materialId}${mmid.techRefId}`)) : true) {
          let result = materialsArr.map((material) => {
            migratedWT && partialQtyMappedMMIDs.push(`${material.materialId}${material.techRefId}`);
            return {
              id: material.id,
              materialId: material.materialId,
              materialName: material.materialName,
              techRefId: material.techRefId,
              type: material.type,
              orderQty: material.orderQty,
              bundleId: material.bundleId,
              featureId: material.featureId,
              sapId: material.sapId,
              sapValue: material.sapValue,
              sapName: material.sapName,
              orderType: material.orderType,
              upsell: material.upsell,
              chargeAmt: material.chargeAmt,
              currency: material.currency,
            };
          });
          return result;
        } else return [];
      }
    }
  };

  const getWorkticketVinMaterialList = async () => {
    setLoader(true);
    let materialList;
    let response = await getVinMaterialList(props.data.ticketId);
    if (response?.status === 200 && response?.data?.data) {
      materialList = response.data.data;
    } else {
      transitModal && setTransitModal(false);
      partialShipmentLostModal && setPartialShipmentLostModal(false);
      notify("Unable to fetch vin material list due to an unexpected error", "error");
      setLoader(false);
    }
    return materialList;
  };

  const handleATPNavigation = async (deliveryDate) => {
    let shippingAddress = [props.data.addressOne, props.data.addressTwo, props.data.city, props.data.stateCode, props.data.zipCode]
      .filter((val) => val)
      .join(", ");
    let subStatusCode =
      selectedERPSubStatus &&
      ERP_SUB_STATUS_DROPDOWN_VALUES.filter((item) => selectedERPSubStatus === item.description)?.length > 0 &&
      ERP_SUB_STATUS_DROPDOWN_VALUES.filter((item) => selectedERPSubStatus === item.description)[0]?.code;
    let materialData;
    if (migratedWT) {
      materialData = filteredWTMaterialList?.map((material) => {
        return {
          id: material.mid,
          materialId: material.materialId,
          materialName: material.materialName,
          techRefId: material.techRefId,
          type: material.processingCode,
          orderQty: material.totalQty,
          bundleId: material.bundleId,
          featureId: [],
          sapId: material.cid,
          sapValue: material.sapId,
          orderType: "",
          upsell: false,
          chargeAmt: "",
          currency: props?.data?.currencyCode,
        };
      });
    } else {
      materialData = await getWorkticketVinMaterialList();
    }
    if (materialData) {
      let VINMaterialMapping = props.data?.vins.map((item) => {
        if (!migratedWT) {
          item.materialList = materialData.find((material) => material.vinId === item.vinId)?.materialList ?? [];
        }
        return {
          lostInTransit: true,
          addressOne: props.data.addressOne,
          addressTwo: props.data.addressTwo,
          atpCheck: `${shippingAddress}, ${props.data.installType}, ${props.data.salesOrg}`,
          city: props.data.city,
          country: props.data.country,
          distributionChannel: props.data.distributionChannel,
          division: props.data.division,
          originalDONumber: originalDONumber?.value,
          subStatus: subStatusCode,
          id: item.vinId,
          installType: props.data.installType,
          make: item?.make,
          materials: migratedWT ? getMMIDData(materialData) : getMMIDData(item.materialList),
          model: item?.model,
          salesOrg: props.data.salesOrg,
          shippingAddress: shippingAddress,
          stateCode: props.data.stateCode,
          vin: item.vin,
          year: item?.year,
          zipCode: props.data.zipCode,
        };
      });

      // data.push({ shippingAddress: VINMaterialMapping });
      // switchTab(0, { data, deliveryDate });
      handleAddLostShipment(VINMaterialMapping, deliveryDate);
    }
  };

  const handleAddLostShipment = async (VINRecordList, deliveryDate) => {
    updateNewrelicStats();
    props.setNotification(null);
    !isLoading && setLoader(true);
    let cspAccDetails = JSON.parse(sessionStorage.getItem("CSPDetails"));
    let data = [];
    partialQtyMappedMMIDs = [];
    let payload = VINRecordList.map((recordList) => {
      if (recordList.materials?.length) {
        return {
          vin: recordList.vin ? recordList.vin : "",
          year: recordList.year ? recordList.year : "",
          make: recordList.make ? recordList.make : "",
          model: recordList.model ? recordList.model : "",
          registrationNumber: "",
          caseNumber: cspAccDetails?.CASEDetails[0]?.CaseNumber || "",
          accountId: cspAccDetails?.AccountID,
          salesOrgId: props?.data?.salesOrg?.split(" ")[0] || "",
          address1: WTAddress ? recordList.addressOne : address.address1.value,
          address2: WTAddress ? recordList.addressTwo : address.address2.value,
          city: WTAddress ? recordList.city : address.city.value,
          country: WTAddress ? recordList.country : address.country.value?.code,
          stateCode: WTAddress ? recordList.stateCode : address.state.value.stateName.split(" - ")[0],
          timezone: "",
          locationFound: true,
          zipCode: WTAddress ? recordList.zipCode : address.zip.value,
          caseId: !isSourceCSP.isSourceCSP ? cspAccDetails?.CASEDetails[0]?.CaseSFDCID : "",
          cspId: isSourceCSP.isSourceCSP ? cspAccDetails?.CSP[0]?.CSPID : "",
          customerName: cspAccDetails?.customerName || "",
          customerNumber: cspAccDetails?.customerNumber || "",
          installType: recordList.installType,
          vztSegment: cspAccDetails?.VZCSegement || "",
          createdBy: `${cspAccDetails?.firstName} ${cspAccDetails?.lastName}`,
          distributionChannel: recordList.distributionChannel,
          division: recordList.division,
          contractAccountId: props?.data?.contractAccount,
          vinId: recordList.id,
          migratedRecord: migratedWT,
          sourceType: isSourceCSP.isSourceCSP ? "CSP" : "CASE",
          materials: recordList.materials.map((item) => {
            return {
              mid: item.mid ? item.mid : item.id,
              materialId: item.materialId,
              featureId: item.featureId,
              bundleId: item.bundleId,
              type: item.type,
              upsell: item.upsell,
              techRefId: item.techRefId,
              contractSapId: item.sapValue,
              sapIdVal: item.sapId,
              orderType: item.orderType,
              orderQty: item.orderQty,
              chargeQty: "",
              chargeAmt: item.chargeAmt || "",
              currency: item.currency || "",
            };
          }),
        };
      }
    }).filter((record) => record);
    if (payload?.length) {
      let response = await addLostShipment(payload.filter((record) => record));
      if (response.status === 200 && response?.data?.message.toLowerCase() === "success") {
        let shippingAddress = [props.data.addressOne, props.data.addressTwo, props.data.city, props.data.stateCode, props.data.zipCode];
        let subStatusCode =
          selectedERPSubStatus &&
          ERP_SUB_STATUS_DROPDOWN_VALUES.filter((item) => selectedERPSubStatus === item.description)?.length > 0 &&
          ERP_SUB_STATUS_DROPDOWN_VALUES.filter((item) => selectedERPSubStatus === item.description)[0]?.code;
        let defaultExchangeType = props?.data?.materials;
        let VINMaterialMapping = response?.data?.data?.map((item) => {
          return {
            lostInTransit: true,
            defaultExchangeType: defaultExchangeType,
            contractAccountId: props?.data?.contractAccount || "",
            addressOne: item.address1,
            addressTwo: item.address2,
            atpCheck: `${[item.address1, item.address2, item.city, item.stateCode, item.zipCode]}, ${item.installType}, ${props.data.salesOrg}`,
            city: item.city,
            country: props.data.country,
            distributionChannel: props.data.distributionChannel,
            division: props.data.division,
            originalDONumber: originalDONumber?.value,
            subStatus: subStatusCode,
            id: item.id,
            installType: item.installType,
            make: item?.make,
            materials: getMMIDData(item.materials, true),
            model: item?.model,
            salesOrg: props.data.salesOrg,
            shippingAddress: [item.address1, item.address2, item.city, item.stateCode, item.zipCode],
            stateCode: item.stateCode,
            vin: item.vin,
            year: item?.year,
            zipCode: item.zipCode,
          };
        });
        let workticketUpdateData = {
          workTicketId: props.data.ticketId,
          erpStatus: selectedERPStatus || "",
          erpSubStatus: subStatusCode || "",
          wareHouseNote: wareHouseNote,
          esnNote: esnNote,
          modifiedBy: userName,
          modifiedByEmail: cspCaseData?.email,
          modifiedByEid: cspCaseData?.Employee_ID,
          assignedTo: cspCaseDetails.assignedTo,
          assignedToEmail: cspCaseDetails.assignedToEmail,
          assignedToEid: cspCaseDetails.assignedToEid,
        };
        setLoader(false);
        data.push({ shippingAddress: VINMaterialMapping });
        switchTab(0, { data, deliveryDate, workticketUpdateData });
      } else {
        props.setNotification(
          <SLNotification key={generate()} title="We are currently experiencing issues, please try again" type="error" fullBleed={true} />
        );
        setPartialShipmentLostModal(false);
        setTransitModal(false);
        setLoader(false);
      }
    } else {
      props.setNotification(
        <SLNotification key={generate()} title="We are currently experiencing issues, please try again" type="error" fullBleed={true} />
      );
      setPartialShipmentLostModal(false);
      setTransitModal(false);
      setLoader(false);
    }
  };

  const updateReturnTicket = async (data) => {
    setLoader(true);
    let payload = {
      returnId: data?.returnId,
      returnMaterialId: editReturnMaterialId,
      materialId: data.wtMaterialId,
      returnQty: data.quantity,
      esn: data.esns,
      returnReason: data.returnReason,
      processingType: data.processingType,
      sapId: data.sapId ? data.sapId : null,
      techRefId: data.techRefId,
      contractId: data.contractId ? data.contractId : null,
      modifiedBy: userName,
      modifiedByEmail: cspCaseData?.email,
      modifiedByEid: cspCaseData?.Employee_ID,
      assignedTo: cspCaseDetails.assignedTo,
      assignedToEmail: cspCaseDetails.assignedToEmail,
      assignedToEid: cspCaseDetails.assignedToEid,
      contractName: data?.contractName?.length > 0 ? data.contractName : null,
      parentWMID: data?.parentWMID,
    };

    let response = await updateReturnItem(payload);
    if (response?.status === 200 && response?.data) {
      // show success message
      notify("Return item updated successfully", "success");
      setTimeout(() => {
        props.setNotification(null);
      }, 5000);
      getReturnTicketList();
    } else {
      //show error
      notify("Unable to update the return item", "error");
    }
    setReturnTicketModalData((prevData) => ({ ...prevData, isShown: false }));
    setLoader(false);
  };

  const saveHandler = (data) => {
    // console.log(data);
    if (data.type === "add") {
      addReturnTicket(data);
    } else {
      // edit api call
      updateReturnTicket(data);
    }
  };
  const captureMaterialId = (materialName, materialId) => {
    switch (materialName) {
      case "materialLineId": {
        emptyMaterialLineId.push(materialId);
        break;
      }
      case "materialId": {
        emptyMaterialId.push(materialId);
        break;
      }
      case "materialName": {
        emptyMaterialName.push(materialId);
        break;
      }
      case "unitMeasure": {
        emptyUnitMeasure.push(materialId);
        break;
      }
      case "processingCode": {
        emptyProcessingCode.push(materialId);
        break;
      }
    }
  };

  let emptyMaterialLineId = [],
    emptyMaterialId = [],
    emptyProcessingCode = [],
    emptyUnitMeasure = [],
    emptyMaterialName = [];

  const handleSubmit = () => {
    //submit api call
    let workTicketData = props.data;
    let returnTicketWithCreatedStatus = returnTicketList.find((returnTicket) => returnTicket?.status?.toLowerCase() === "return_created");
    let returnMaterials = returnTicketWithCreatedStatus.returnMaterial.map((material) => {
      let sapId = material.sapId || null;
      let techRefId = material.techRefId || null;
      let materialData =
        workTicketData.materials.find((mmidDetails) => {
          let mmidDetailssapId = mmidDetails.sapId || null;
          let mmidDetailstechRefId = mmidDetails.techRefId || null;
          return material.mmid === mmidDetails.materialId && sapId === mmidDetailssapId && techRefId === mmidDetailstechRefId;
        }) ?? {};
      return {
        id: materialData?.mid,
        materialId: materialData?.materialId ?? "",
        materialName: materialData?.materialName ?? "",
        returnMaterialId: material?.returnMaterialId ?? "",
        quantityATP: "", // not needed
        dateATP: "", // not needed
        summaryQty: materialData?.totalQty,
        materialLineId: material?.materialLineId ?? "",
        unitMeasure: materialData?.unitOfMeasure ?? "",
        techRefId: materialData?.techRefId ?? "",
        processingCode: material?.processingType ?? "",
        installedQuantity: "",
        chargePrice: "",
        family: "",
        customerCharge: "",
        serviceProcessType: "",
        invoiceRelevant: "",
        endTime: "",
        customerPONumber: isSourceCSP.isSourceCSP ? workTicketData.cspCustomerPONumber : "",
        customerPONumberKut: !isSourceCSP.isSourceCSP ? workTicketData.cspCustomerPONumber : "",
        baseItemId: materialData?.bundleId ?? "",
        esnToBeReturned: material.esn,
        sapId: materialData?.sapId ? materialData?.sapId : null,
        contractId: materialData?.cid ? materialData?.cid : null,
        contractName: materialData?.contractName?.length > 0 ? materialData?.contractName : null,
      };
    });
    let payload = {
      returnId: returnTicketWithCreatedStatus.returnId,
      assignTo: workTicketData.assignedTo ?? "",
      crmReferenceId: workTicketData.crmReferenceId ?? "",
      currencyCode: workTicketData.currencyCode ?? "",
      documentType: isSourceCSP.isSourceCSP ? "ZINS" : "ZSRQ",
      erpTicketType: isSourceCSP.isSourceCSP ? "ZINS" : "ZSRQ",
      documentDate: moment(new Date()).format("YYYYMMDD"),
      salesOrg: workTicketData.salesOrg?.substring(0, 4) ?? "",
      distributionChannel: workTicketData.distributionChannel || null,
      division: workTicketData.division || null,
      contractAccount: workTicketData.contractAccount ?? "",
      erpVendorId: "",
      salesRepId: workTicketData.assignedTo ?? "",
      notesToTechnician: "",
      vendorMessage: "",
      notesForWarehouse: "", // need to update it once it is available
      subStatus: selectedERPSubStatus ?? "",
      oldDeliveryNo: "",
      requestDate: moment(new Date()).format("YYYYMMDD"),
      providerOrder: isSourceCSP.isSourceCSP ? cspCaseData?.CSP?.find((csp) => csp.DefaultCSP === "1")?.Provider_Order ?? "" : "",
      erpStatus: "",
      materials: returnMaterials,
      modifiedBy: `${cspCaseData?.firstName} ${cspCaseData?.lastName}`,
      modifiedByEmail: cspCaseData?.email,
      modifiedByEid: cspCaseData?.Employee_ID,
      assignedTo: cspCaseDetails.assignedTo,
      assignedToEmail: cspCaseDetails.assignedToEmail,
      assignedToEid: cspCaseDetails.assignedToEid,
      erpProductRecipientPartyId: cspCaseData?.ResellerBusinessPartnerNumber || workTicketData.erpPartnerNumber || "",
      partners: [
        {
          party: cspCaseData?.ResellerBusinessPartnerNumber || workTicketData.erpPartnerNumber || "",
          partRoleCode: "AG",
        },
        {
          party: cspCaseData?.ERP_Primary_Contact_NUmber ?? "",
          partRoleCode: "AP",
        },
        {
          party: workTicketData.erpPartnerNumber ?? "",
          partRoleCode: "WE",
          accountName: workTicketData.accountName ?? "",
          contactName: workTicketData.contactName || "NA",
          contactPhone: workTicketData.phone || "NA",
          contactEmail: workTicketData.email || "NA",
          street: workTicketData.addressOne ?? "",
          city: workTicketData.city ?? "",
          state: workTicketData.stateCode ?? "",
          country: workTicketData.country ?? "",
          zip: workTicketData.zipCode ?? "",
          telephone: "",
        },
      ],
    };
    let errorMsg = [];
    let missingFieldsArray = [];
    for (const key of Object.keys(payload)) {
      if (
        key === "crmReferenceId" ||
        key === "salesOrg" ||
        key === "contractAccount" ||
        key === "currencyCode" ||
        key === "assignTo" ||
        key === "distributionChannel" ||
        key === "salesRepId" ||
        key === "division"
      ) {
        if (payload[key] === "" || payload[key] === undefined || payload[key] === null) {
          missingFieldsArray.push(key);
        }
      }
    }
    if (missingFieldsArray.length > 0) {
      return setTimeout(() => {
        notify(
          "Unable to submit return due to missing information. Kindly contact IT team for support. Missing information: " +
            missingFieldsArray.toString() +
            ".",
          "error",
          false
        );
      }, 10);
    } else if (
      cspCaseData?.ResellerBusinessPartnerNumber === null &&
      workTicketData.erpPartnerNumber === null &&
      cspCaseData?.ERP_Primary_Contact_NUmber === null
    ) {
      return setTimeout(() => {
        notify(
          "Unable to submit return due to missing information. Kindly contact IT team for support. Missing information: Reseller Business Partner Number).",
          "error",
          false
        );
      }, 10);
    } else {
      payload?.materials?.filter((data) => {
        for (const key of Object.keys(data)) {
          if (key === "materialName" || key === "materialId" || key === "materialLineId" || key === "unitMeasure" || key === "processingCode") {
            if (data[key] === "" || data[key] === undefined || data[key] === null) {
              captureMaterialId(key, data.materialId);
            }
          }
        }
      });
      if (emptyMaterialId.length > 0) errorMsg.push(` ${"Material Id"} is missing for ${emptyMaterialId.toString()}`);
      if (emptyMaterialLineId.length > 0) errorMsg.push(` ${"Material Line Id"} is missing for ${emptyMaterialLineId.toString()}`);
      if (emptyMaterialName.length > 0) errorMsg.push(` ${"Material Name"} is missing for ${emptyMaterialName.toString()}`);
      if (emptyUnitMeasure.length > 0) errorMsg.push(` ${"Unit of Measure"} is missing for ${emptyUnitMeasure.toString()}`);
      if (emptyProcessingCode.length > 0) errorMsg.push(` ${"Processing Code"} is missing for ${emptyProcessingCode.toString()}`);
      if (errorMsg.length > 0) {
        return setTimeout(() => {
          notify(
            <>
              {"Unable to submit return due to below missing information. Kindly contact IT team for support. Missing Information: "}
              {errorMsg.map((msg) => (
                <div key={generate()}>{msg}</div>
              ))}
            </>,
            "error",
            false
          );
        }, 10);
      }
    }
    submitReturnTicket(payload);
  };

  const submitReturnTicket = async (payload) => {
    setLoader(true);
    let response = await submitReturnItem(payload);
    if (response?.status === 200 && response?.data) {
      // show success message
      notify("Successfully submitted the return item", "success");
      setTimeout(() => {
        props.setNotification(null);
      }, 5000);
      getReturnTicketList();
    } else {
      //show error
      notify("Unable to submit the return item", "error");
    }
    setLoader(false);
  };

  const getReturnDeleteData = (data) => {
    setReturnDeleteData(data);
    setEditReturnMaterialId(data.returnMaterialId);
    setReturnDeleteConfirmModal(true);
  };

  const renderStatus = () => {
    let workTicketStatus = props?.data?.status?.toLowerCase();
    let statusIcon;
    switch (workTicketStatus) {
      case "error":
        statusIcon = ErrorImg;
        break;
      case "released":
        statusIcon = ReleasedImg;
        break;
      case "cancelled":
        statusIcon = CancelImg;
        break;
      case "return submitted":
        statusIcon = ReturnSubmittedImg;
        break;
      default:
        statusIcon = InProgressImg;
    }
    return (
      <UltraMediumText fontWeight="normal" marginTop="12px">
        {props?.data?.status ? (
          <>
            <img src={statusIcon} alt="Status-Icon" height="16px" width="16px" css={{ marginRight: "8px" }} />
            <span style={{ position: "relative", bottom: "3px" }}>{props?.data?.status}</span>
          </>
        ) : (
          <span>--</span>
        )}
      </UltraMediumText>
    );
  };
  const handleReturnItemDelete = async () => {
    props.setNotification(null);
    setLoader(true);
    let returnTicketWithCreatedStatus = returnTicketList.find((returnTicket) => returnTicket?.status?.toLowerCase() === "return_created");
    let payload = {
      returnId: returnTicketWithCreatedStatus?.returnId,
      modifiedBy: userName,
      modifiedByEmail: cspCaseData?.email,
      modifiedByEid: cspCaseData?.Employee_ID,
      assignedTo: cspCaseDetails.assignedTo,
      assignedToEmail: cspCaseDetails.assignedToEmail,
      assignedToEid: cspCaseDetails.assignedToEid,
    };
    let response = await deleteReturnItem(editReturnMaterialId, payload);
    if (response?.status === 200 && response?.data) {
      // show success message
      setReturnDeleteConfirmModal(false);
      notify("Return item deleted successfully", "success");
      setTimeout(() => {
        props.setNotification(null);
      }, 5000);
      getReturnTicketList();
    } else {
      //show error
      setReturnDeleteConfirmModal(false);
      notify("Unable to delete the return item", "error");
    }
    setLoader(false);
  };

  const handleWorkTicketUpdate = async () => {
    setLoader(true);
    let erpSubStatus = ERP_SUB_STATUS_DROPDOWN_VALUES.filter((record) => record.description === selectedERPSubStatus)[0]?.code;
    let payload = {
      workTicketId: props.data.ticketId,
      erpStatus: selectedERPStatus || "",
      erpSubStatus: erpSubStatus || "",
      wareHouseNote: wareHouseNote,
      esnNote: esnNote,
      modifiedBy: userName,
      modifiedByEmail: cspCaseData?.email,
      modifiedByEid: cspCaseData?.Employee_ID,
      assignedTo: cspCaseDetails.assignedTo,
      assignedToEmail: cspCaseDetails.assignedToEmail,
      assignedToEid: cspCaseDetails.assignedToEid,
    };
    let response = await workticketUpdate(payload);
    if (response?.status === 200 && response?.data) {
      notify("Successfully updated the workTicket", "success");
      setTimeout(() => {
        props.setNotification(null);
      }, 5000);
      redirectToPrevPage();
    } else {
      notify("We are currently experiencing issues, please try again", "error");
    }
    setLoader(false);
  };

  const isAddReturnItemButtonDisabled = useMemo(() => {
    if (limitWT || hasPrivilege(Privileges.ENABLE_WORKTICKET_PORTAL_READONLY)) return true;
    return props?.data?.materials?.filter((material) => material.salesOrder)?.length ? false : true;
  }, [props]);

  return (
    <>
      {partialShipmentLostModal && (
        <PartialShipmentLostModal
          partialShipmentLostModal={partialShipmentLostModal}
          setPartialShipmentLostModal={setPartialShipmentLostModal}
          setTransitModal={setTransitModal}
          setPartialTransitLostMMID={setPartialTransitLostMMID}
          materialList={
            _.cloneDeep(
              filteredWTMaterialList?.map((mat) => {
                mat.ESNLost = [];
                mat.ESNQtyLost = 0;
                return mat;
              })
            ) || []
          }
        />
      )}
      {transitModal && (
        <ConfirmDeliveryDateModal
          confirmDeliveryDateModal={transitModal}
          setConfirmDeliveryDateModal={setTransitModal}
          handleATPCheck={handleATPNavigation}
          partialTransitLostMMID={partialTransitLostMMID}
          lostInTransit={true}
        />
      )}
      <Loader active={isLoading} fullscreen />
      <div tabIndex={0}>
        <AccordionWrapper onClick={() => erpStatusAccordianClickCount === 0 && getERPStatusData()}>
          <Accordion activeIndex={1} expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
            <AccordionTab header="Overview">
              {/* Overview Code starts from here */}
              {!WTAddress && migratedWT && (selectedERPSubStatus === FULL_SHIPMENT_LOST || selectedERPSubStatus === PARTIAL_SHIPMENT_LOST) && (
                <SLNotification key={generate()} type="warning" title="Make sure to Validate Address before saving." fullBleed={true} />
              )}
              {erpStatusAccordianError && (
                <>
                  <Spacer bottom="1rem" />
                  <SLNotification
                    key={generate()}
                    type={"error"}
                    title={"Unable to fetch data due to an unexpected error"}
                    fullBleed={true}
                    ref={clickRef}
                  />
                </>
              )}
              <Spacer top="1rem" />
              <FlexRow>
                <OverviewColumn tabIndex={0}>
                  <Label> Ticket Type </Label>
                  <UltraMediumText fontWeight="normal" marginTop="12px">
                    {props.data?.erpTicketType || "--"}
                  </UltraMediumText>
                </OverviewColumn>
                <OverviewColumn tabIndex={0}>
                  <Label> Status </Label>
                  {renderStatus()}
                </OverviewColumn>
                <OverviewColumn>
                  <CustomDropdown
                    width="90%"
                    label="1ERP Status"
                    ariaLabel="1ERP Status"
                    tabIndex={0}
                    value={selectedERPStatus ? selectedERPStatus : "Please Select"}
                    onChange={(e) => setSelectedERPStatus(e.target.value)}
                    marginBottom="10px"
                  >
                    <>
                      <option value="">Please select</option>
                      {OneERPStatus.map((type) => (
                        <option key={type.code} value={type.code}>
                          {type.description}
                        </option>
                      ))}
                    </>
                  </CustomDropdown>
                </OverviewColumn>
                <OverviewColumn>
                  <CustomDropdown
                    width="90%"
                    label="1ERP Sub Status"
                    tabIndex={0}
                    ariaLabel="1ERP Sub Status"
                    value={selectedERPSubStatus ? selectedERPSubStatus : "Please Select"}
                    onChange={(e) => {
                      e.target.value !== FULL_SHIPMENT_LOST &&
                        setOriginalDONumber({ value: "", error: true, errorText: "Enter Original Delivery Number" });
                      setSelectedERPSubStatus(e.target.value);
                    }}
                    marginBottom="10px"
                  >
                    <option disabled>Please Select</option>
                    {OneERPSubStatus.map((subStatus) => (
                      <option key={subStatus.code}>{subStatus.description}</option>
                    ))}
                  </CustomDropdown>
                </OverviewColumn>
                {selectedERPSubStatus === FULL_SHIPMENT_LOST && (
                  <OverviewColumn>
                    <CustomInput
                      id="customColCss"
                      width="95%"
                      type="number"
                      label="Original DN Number"
                      ariaLabel="Original DN Number"
                      tabIndex={0}
                      value={originalDONumber?.value && originalDONumber.value}
                      error={originalDONumber?.error}
                      errorText={originalDONumber?.errorText}
                      onChange={(e) =>
                        e.target.value
                          ? mmidDNNumber.length > 0 && mmidDNNumber.includes(e.target.value)
                            ? setOriginalDONumber({ value: e.target.value, error: false, errorText: "" })
                            : setOriginalDONumber({ value: e.target.value, error: true, errorText: "Enter the valid original DN number" })
                          : setOriginalDONumber({ value: e.target.value, error: true, errorText: "Enter Original Delivery Number" })
                      }
                      css={{ marginTop: "0.2rem" }}
                    />
                  </OverviewColumn>
                )}
                <OverviewColumn tabIndex={0}>
                  {/* <SmallText>Assigned To</SmallText>
                  <CustomisedInput
                    css={{ marginBottom: "10px" }}
                    value={selectedAssignedTo}
                    ariaLabel="Assigned To"
                    tabIndex={0}
                    onChange={(e) => setAssignedTo(e.value)}
                    options={selectAssignedTo}
                    optionLabel="name"
                    placeholder="Please select"
                    filter
                    resetFilterOnHide
                  /> */}
                  <Label> Owner </Label>
                  <UltraMediumText fontWeight="normal" marginTop="12px">
                    {props.data?.ownerName || "--"}
                  </UltraMediumText>
                </OverviewColumn>
                <OverviewColumn tabIndex={0}>
                  <Label> Created By </Label>
                  <UltraMediumText fontWeight="normal" marginTop="12px">
                    {props.data?.createdBy || "--"}
                  </UltraMediumText>
                </OverviewColumn>
                <OverviewColumn tabIndex={0}>
                  <Label> Created On </Label>
                  <UltraMediumText fontWeight="normal" marginTop="12px">
                    {props.data?.createdOn ? moment(props.data.createdOn).format("MMM D, yyyy") : `--`}
                  </UltraMediumText>
                </OverviewColumn>
                <OverviewColumn tabIndex={0}>
                  <Label> CRM Reference ID </Label>
                  <UltraMediumText fontWeight="normal" marginTop="12px">
                    {crmReferenceId}
                  </UltraMediumText>
                </OverviewColumn>
                <OverviewColumn tabIndex={0}>
                  <Label> Modified By </Label>
                  <UltraMediumText fontWeight="normal" marginTop="12px">
                    {props.data?.modifiedBy || `--`}
                  </UltraMediumText>
                </OverviewColumn>
                <OverviewColumn tabIndex={0}>
                  <Label> Changed On </Label>
                  <UltraMediumText fontWeight="normal" marginTop="12px">
                    {props.data?.modifiedAt ? moment(props.data.modifiedAt).format("MMM D, yyyy") : `--`}
                  </UltraMediumText>
                </OverviewColumn>
                <OverviewColumn tabIndex={0}>
                  <Label> Customer Contract Account </Label>
                  <UltraMediumText fontWeight="normal" marginTop="12px">
                    {props.data?.contractAccount ?? "--"}
                  </UltraMediumText>
                </OverviewColumn>
                <OverviewColumn tabIndex={0}>
                  <Label> Requested Delivery Date </Label>
                  <UltraMediumText fontWeight="normal" marginTop="12px">
                    {props.data?.requestDate ? moment(props.data.requestDate).format("MMM D, yyyy") : "--"}
                  </UltraMediumText>
                </OverviewColumn>
                <OverviewColumn tabIndex={0}>
                  <Label> Sales Org </Label>
                  <UltraMediumText fontWeight="normal" marginTop="12px">
                    {props.data?.salesOrg || "--"}
                  </UltraMediumText>
                </OverviewColumn>
                <OverviewColumn tabIndex={0}>
                  <Label> Division </Label>
                  <UltraMediumText fontWeight="normal" marginTop="12px">
                    {props?.data?.division ? getFullName(DIVISION_DROPDOWN_VALUES, props?.data?.division) : "--"}
                  </UltraMediumText>
                </OverviewColumn>
                <OverviewColumn tabIndex={0}>
                  <Label> Distribution Channel </Label>
                  <UltraMediumText fontWeight="normal" marginTop="12px">
                    {props?.data?.distributionChannel ? getFullName(DISTRIBUTION_CHANNEL_DROPDOWN_VALUES, props?.data?.distributionChannel) : "--"}
                  </UltraMediumText>
                </OverviewColumn>
                {props.data?.status === "Cancelled" && (
                  <OverviewColumn tabIndex={0}>
                    <Label> Reject Reason </Label>
                    <UltraMediumText fontWeight="normal" marginTop="12px">
                      {props.data?.cancellationReason || "--"}
                    </UltraMediumText>
                  </OverviewColumn>
                )}
              </FlexRow>
            </AccordionTab>
          </Accordion>
        </AccordionWrapper>
      </div>

      <AccordionWrapper>
        <Accordion activeIndex={2} expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
          <AccordionTab header="Customer Information">
            {/* Customer information Code goes here */}
            <Spacer top="1rem" />
            <FlexRow>
              <OverviewColumn tabIndex={0}>
                <Label> Install Type </Label>
                <UltraMediumText fontWeight="normal" marginTop="12px">
                  {props?.data?.installType || "--"}
                </UltraMediumText>
              </OverviewColumn>
              <OverviewColumn tabIndex={0}>
                <Label> Contact </Label>
                <UltraMediumText fontWeight="normal" marginTop="12px">
                  {props?.data?.contactName || "--"}
                </UltraMediumText>
              </OverviewColumn>
              <OverviewColumn tabIndex={0}>
                <Label> Email </Label>
                <UltraMediumText fontWeight="normal" marginTop="12px">
                  {props?.data?.email || "--"}
                </UltraMediumText>
              </OverviewColumn>
              <OverviewColumn tabIndex={0}>
                <Label> Phone </Label>
                <UltraMediumText fontWeight="normal" marginTop="12px">
                  {props?.data?.phone || "--"}
                </UltraMediumText>
              </OverviewColumn>
              {WTAddress && (
                <OverviewColumn tabIndex={0}>
                  <Label> Shipping Address </Label>
                  <UltraMediumText fontWeight="normal" marginTop="12px">
                    {shippingAddress || "--"}
                  </UltraMediumText>
                </OverviewColumn>
              )}
            </FlexRow>
            {!WTAddress && migratedWT && (
              <ValidateAddress
                address={address}
                setAddress={setAddress}
                vehicleData={null}
                showHistoricalAddressDropdown={false}
                setLoader={setLoader}
              />
            )}
            {/* <SLGridLayout role="table">
              <SLRowLayout role="row">
                <CompactCol colSizes={{ mobile: 6, tablet: 6, desktop: 12 }} css={StyledCol}>
                  <SLGridLayout>
                    <SLRowLayout role="row">
                      <CompactCol tabIndex={0} colSizes={{ mobile: 4, tablet: 12, desktop: 3 }}>
                        <Label> Install Type </Label>
                        <UltraMediumText fontWeight="normal" marginTop="12px">
                          {props?.data?.installType || "--"}
                        </UltraMediumText>
                      </CompactCol>
                      <CompactCol tabIndex={0} colSizes={{ mobile: 4, tablet: 12, desktop: 3 }}>
                        <Label> Contact </Label>
                        <UltraMediumText fontWeight="normal" marginTop="12px">
                          {props?.data?.contactName || "--"}
                        </UltraMediumText>
                      </CompactCol>
                      <CompactCol tabIndex={0} colSizes={{ mobile: 4, tablet: 12, desktop: 3 }}>
                        <Label> Email </Label>
                        <UltraMediumText fontWeight="normal" marginTop="12px">
                          {props?.data?.email || "--"}
                        </UltraMediumText>
                      </CompactCol>
                      <CompactCol tabIndex={0} colSizes={{ mobile: 4, tablet: 12, desktop: 3 }}>
                        <Label> Phone </Label>
                        <UltraMediumText fontWeight="normal" marginTop="12px">
                          {props?.data?.phone || "--"}
                        </UltraMediumText>
                      </CompactCol>
                    </SLRowLayout>
                  </SLGridLayout>
                </CompactCol>
              </SLRowLayout>
              <SLRowLayout>
                <CompactCol colSizes={{ mobile: 6, tablet: 6, desktop: 12 }} css={StyledCol}>
                  <SLGridLayout>
                    <SLRowLayout>
                      <CompactCol tabIndex={0} colSizes={{ mobile: 4, tablet: 12, desktop: 3 }}>
                        <Label> Shipping Address </Label>
                        {!migratedWT ? (
                          <UltraMediumText fontWeight="normal" marginTop="12px">
                            {shippingAddress || "--"}
                          </UltraMediumText>
                        ) : (
                          <ValidateAddress
                            address={address}
                            setAddress={setAddress}
                            vehicleData={false}
                            showHistoricalAddressDropdown={false}
                            setLoader={setLoader}
                          />
                        )}
                      </CompactCol>
                    </SLRowLayout>
                  </SLGridLayout>
                </CompactCol>
              </SLRowLayout>
            </SLGridLayout> */}
          </AccordionTab>
        </Accordion>
      </AccordionWrapper>

      <AccordionWrapper>
        <Accordion activeIndex={3} expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
          <AccordionTab tabIndex={0} aria-label="Scheduling" header="Scheduling">
            <MigratedSchedulingData tabIndex={0} data={props?.data?.erpMigrationData} />
          </AccordionTab>
        </Accordion>
      </AccordionWrapper>

      <AccordionWrapper>
        <Accordion activeIndex={5} expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
          <AccordionTab header="Notes">
            <Spacer top="10px"></Spacer>
            <SLRowLayout>
              <Spacer top="1rem" />
              <CompactCol
                colSizes={{ mobile: 6, tablet: 6, desktop: 12 }}
                css={{
                  width: "100% !important",
                  paddingLeft: "2px !important",
                  padding: "10px !important",
                }}
              >
                <SLGridLayout>
                  <SLRowLayout>
                    <CompactCol colSizes={{ mobile: 4, tablet: 12, desktop: 6 }}>
                      <SpaceNote>
                        <SmallText fontWeight="normal">Warehouse Note</SmallText>
                        <TextArea
                          maxLength="500"
                          aria-label="Warehouse Note"
                          // value={props.data?.notesToWareHouse}
                          tabIndex={0}
                          value={wareHouseNote}
                          onChange={(e) => setWareHouseNote(e.target.value)}
                          autoResize="none"
                          placeholder="Type here"
                        />
                      </SpaceNote>
                    </CompactCol>
                    <CompactCol colSizes={{ mobile: 4, tablet: 12, desktop: 6 }}>
                      <SpaceNote>
                        <SmallText fontWeight="normal">ESN Note</SmallText>
                        <TextArea
                          maxLength="500"
                          aria-label="ESN Note"
                          autoResize="none"
                          placeholder="Type here"
                          value={esnNote}
                          onChange={(e) => setESNNote(e.target.value)}
                        />
                      </SpaceNote>
                    </CompactCol>
                  </SLRowLayout>
                </SLGridLayout>
              </CompactCol>
            </SLRowLayout>
          </AccordionTab>
        </Accordion>
      </AccordionWrapper>

      {/* Document Flow is commented for hide Document Flow in both CSP and Case */}
      {/* {!isSourceCSP.isSourceCSP && (
        <AccordionWrapper>
          <Accordion activeIndex={3} expandIcon="pi pi-chevron-up" collapseIcon="pi pi-chevron-down">
            <AccordionTab header="Document Flow">
              <Spacer bottom="1rem" />
              <Line type="primary" surface="light" />
              <TableWrapper>
                <DocumentFlowTable TicketLevel={true} data={documentFlowData} />
              </TableWrapper>
            </AccordionTab>
          </Accordion>
        </AccordionWrapper>
      )} */}

      {!individualReturn && (
        <>
          <AccordionWrapper onClick={() => returnAccordianClickCount === 0 && getReturnTicketList()}>
            <Accordion activeIndex={4} expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
              <AccordionTab aria-label="Return" header="Return">
                <div tabIndex={0} aria-label="return">
                  {returnTicketError && (
                    <>
                      <Spacer bottom="1rem" />
                      <SLNotification
                        key={generate()}
                        type={"error"}
                        title={"Error while fetching the return tickets"}
                        fullBleed={true}
                        ref={clickRef}
                      />
                    </>
                  )}
                  <GridComponent
                    isSelectionEnabled={false}
                    noDataMessage="No data available to display."
                    rows={returnTicketList}
                    columns={RETURN_TABLE_COL}
                    whiteSpace="unset"
                    hideGlobalFilter={true}
                    paginator={false}
                    rowExpansionTemplate={returnTableRowExpansionTemplate}
                    expandedRow={expandedRow}
                  />
                </div>
                <Spacer bottom="10px" />
                <FlexRow>
                  <CustomSLButton
                    aria-label="Add Return Item"
                    role="button"
                    disabled={isAddReturnItemButtonDisabled || props.data?.allowCancel}
                    aria-disabled={isAddReturnItemButtonDisabled || props.data?.allowCancel}
                    secondary
                    onClick={() => handleReturnTicketModal("add", workTicketData)}
                  >
                    Add Return Item
                  </CustomSLButton>
                  <CustomSLButton
                    secondary
                    aria-label="Submit Return"
                    role="button"
                    aria-disabled={!returnTicketList.map((returnTicket) => returnTicket?.status?.toLowerCase()).includes("return_created")}
                    disabled={!returnTicketList.map((returnTicket) => returnTicket?.status?.toLowerCase()).includes("return_created")}
                    onClick={() => handleSubmit()}
                  >
                    Submit Return
                  </CustomSLButton>
                </FlexRow>
              </AccordionTab>
            </Accordion>
          </AccordionWrapper>
        </>
      )}
      <Spacer top="2rem" />
      <FlexRow>
        <CustomSLButton
          primary
          aria-label="Save"
          role="button"
          aria-disabled={saveBtnDisabled}
          disabled={saveBtnDisabled}
          onClick={() => {
            selectedERPSubStatus === FULL_SHIPMENT_LOST || selectedERPSubStatus === PARTIAL_SHIPMENT_LOST ? handleSave() : handleWorkTicketUpdate();
          }}
        >
          Save
        </CustomSLButton>
        <CustomSLButton
          aria-label="Cancel"
          role="button"
          disabled={limitWT || hasPrivilege(Privileges.ENABLE_WORKTICKET_PORTAL_READONLY)}
          aria-disabled={limitWT || hasPrivilege(Privileges.ENABLE_WORKTICKET_PORTAL_READONLY)}
          secondary
          onClick={() => redirectToPrevPage()}
        >
          Cancel
        </CustomSLButton>
      </FlexRow>
      {returnTicketModalData.isShown && (
        <ReturnTicketModal
          returnTicketModalData={returnTicketModalData}
          returnTicketList={returnTicketList}
          setReturnTicketModalData={setReturnTicketModalData}
          saveHandler={saveHandler}
          rtnReson={rtnReson}
          esitEsn={esitEsn}
          retnQty={retnQty}
          editReturnMaterialProcessingType={editReturnMaterialProcessingType}
          defaultReturnType={defaultReturnType ?? ""}
          editReturnData={editReturnData}
        />
      )}

      {returnDeleteConfirmModal && (
        <Modal
          role="dialog"
          ariaLabel="Delete Item"
          opened={true}
          disableOutsideClick
          disableAnimation={returnDeleteConfirmModal}
          closeButton={
            <FlexRow justifyContent="space-between" onClick={(event) => event.preventDefault()}>
              <div css={{ paddingLeft: "0.7rem" }}>
                <Title size="medium">Delete Item</Title>
              </div>
              <div style={{ marginRight: "1.3rem" }}>
                <div style={{ cursor: "pointer" }} onClick={() => setReturnDeleteConfirmModal(false)}>
                  <Icon
                    aria-label="close"
                    alt="close"
                    role="img"
                    name="close"
                    size="medium"
                    color="#000000"
                    style={{ cursor: "pointer", outline: "none" }}
                  />
                </div>
              </div>
            </FlexRow>
          }
        >
          <ModalBody css={{ padding: "16px 0 16px 16px !important" }}>
            <p>Are you sure you would like to delete the item?</p>
            <Spacer top="1rem" />
            <Spacer bottom="10px" />
            <FlexRow>
              <CustomSLButton aria-label="Delete" role="button" css={{ padding: "0 10px 0 10px" }} primary onClick={handleReturnItemDelete}>
                Delete
              </CustomSLButton>
              <CustomSLButton aria-label="Cancel" role="button" secondary onClick={() => setReturnDeleteConfirmModal(false)}>
                Cancel
              </CustomSLButton>
            </FlexRow>
          </ModalBody>
        </Modal>
      )}
    </>
  );
}

export const WorkViewItemTableMetadata = {
  name: "Work View Item Table",
  id: "Work_View_Item_Table",
  component: lazy(() => import("./WorkViewItemTable")),
  route: "/work_view_item_table",
};
