import React, { useState, useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import { OverlayPanel } from "primereact/overlaypanel";
import { Loader } from "@vds/loaders";
import moreIcon from "../../assets/icons/more.png";
import { SmallText } from "../../components/core/Utility/index";
import { switchTab } from "./utils";
import moment from "moment";
import { C4SContext } from "../../context/c4sContext";
import Icon from "@vds/icons";
import { Title } from "@vds/typography";
import { Modal, ModalBody, ModalFooter, ModalTitle } from "@vds/modals";
import { cancelWorkticket } from "../../services/C4S Decommission - Replacement/cancelWorkticket";
import { CompactCol, CustomSLButton, FlexRow, Spacer } from "../../components/core/utility";
import RejectReasonModal from "./RejectReasonModal";

const Options = styled.div`
  font-family: Verizon-NHG-eTX, Helvetica, Ariel, sans-serif;
  font-size: 12px;
  color: #000;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0.4rem")};
  cursor: pointer;
`;

const ThreeDotsTemplate = React.memo(function ThreeDotsTemplate({ ticketDetails, callDeleteTicket, notify, getWorkTicketList, id, userId }) {
  const { source, workTicketInfo, notification } = useContext(C4SContext);
  const cspDetails = JSON.parse(sessionStorage.getItem("CSPDetails"));
  const [component, setComponent] = useState(null);
  const [isLoading, setLoader] = useState(false);
  let moreOperations = useRef(null);
  const [isCancelOptionModal, setIsCancelOptionModal] = useState(false);
  const [rejectReasonModalStatus, setRejectReasonModalStatus] = useState(false);
  // const [isCancelTicket, setIsCancelTicket] = useState(true);

  // useEffect(() => {
  //   checkIsCancel();
  // }, [ticketDetails]);

  const handleHamburgerActions = (action) => {
    moreOperations.current.hide();
    switch (action) {
      // case "ReRelease":
      //   break;
      case "Schedule":
        if (
          ticketDetails.installType === "VMI" &&
          (ticketDetails?.status?.toLowerCase() === "released" || ticketDetails?.status?.toLowerCase() === "shipped")
        ) {
          redirectToAddJobPage();
        }
        break;
      case "Cancel":
        setIsCancelOptionModal(true);
        break;
      case "CancelShipment":
        setRejectReasonModalStatus(true);
        break;
    }
  };

  const handleCancelWorkTicket = async () => {
    let cspAccDetails = cspDetails?.CSP?.filter((cspDetail) => cspDetail.DefaultCSP === "1")[0] || "";
    let salesOrgNumber = ticketDetails?.salesOrg.split(" ")[0];
    let payload = {
      cspId: cspAccDetails?.CSPID || "",
      workticketId: ticketDetails.ticketId ?? "",
      //createdBy: `${cspDetails.firstName} ${cspDetails.lastName}` ?? "",
      salesOrg: salesOrgNumber ?? "",
      erpTicketType: source.source === "CSP" ? "ZINS" : "ZSRQ",
      modifiedBy: `${cspDetails?.firstName} ${cspDetails?.lastName}`,
      modifiedByEmail: cspDetails?.email || "",
      modifiedByEid: cspDetails?.Employee_ID || "",
      assignedTo:
        cspDetails?.source === "CSP"
          ? cspDetails?.CSP.length
            ? cspDetails?.CSP.filter((csp) => csp?.DefaultCSP === "1")[0].CSPOwner
            : null
          : cspDetails?.CASEDetails.length
          ? cspDetails?.CASEDetails.filter((cases) => cases.DefaultCase === "1")[0].CaseOwner
          : null,
      assignedToEmail:
        cspDetails?.source === "CSP"
          ? cspDetails?.CSP.length
            ? cspDetails?.CSP.filter((csp) => csp?.DefaultCSP === "1")[0].CSPOwnerEmail
            : null
          : cspDetails?.CASEDetails.length
          ? cspDetails?.CASEDetails.filter((cases) => cases.DefaultCase === "1")[0].CaseOwnerEmail
          : null,
      assignedToEid:
        cspDetails?.source === "CSP"
          ? cspDetails?.CSP.length
            ? cspDetails?.CSP.filter((csp) => csp?.DefaultCSP === "1")[0].CSPOwnerEId
            : null
          : cspDetails?.CASEDetails.length
          ? cspDetails?.CASEDetails.filter((cases) => cases.DefaultCase === "1")[0].CaseOwnerEId
          : null,
    };
    let missingFieldsArray = [];
    for (const key of Object.keys(payload)) {
      if (key === "workticketId" || key === "salesOrg") {
        if (payload[key] === "" || payload[key] === undefined || payload[key] === null) {
          missingFieldsArray.push(key);
        }
      }
    }
    if (missingFieldsArray.length > 0) {
      setIsCancelOptionModal(false);
      return setTimeout(() => {
        notify(
          "Unable to cancel work ticket due to missing information. Kindly contact IT team for support. Missing information: " +
            missingFieldsArray.toString() +
            ".",
          "error",
          false
        );
      }, 10);
    }
    setLoader(true);
    let response = await cancelWorkticket(payload);

    if (response.status === 200 && response?.data?.statusCode === "200") {
      notify("Ticket has been canceled successfully!", "success");
      getWorkTicketList();
      setIsCancelOptionModal(false);
      setTimeout(() => {
        notification.setNotification(null);
      }, 3000);
    } else {
      notify("We are currently experiencing issues, please try again", "error");
      setIsCancelOptionModal(false);
    }
    setLoader(false);
  };

  const handleCancelShipment = async (desc, subCode) => {
    let cspAccDetails = cspDetails?.CSP?.filter((cspDetail) => cspDetail.DefaultCSP === "1")[0] || "";
    let salesOrgNumber = ticketDetails?.salesOrg.split(" ")[0];
    let payload = {
      cspId: cspAccDetails?.CSPID || "",
      workticketId: ticketDetails.ticketId ?? "",
      cancellationReason: desc,
      cancellationReasonCode: subCode,
      //createdBy: `${cspDetails.firstName} ${cspDetails.lastName}` ?? "",
      salesOrg: salesOrgNumber ?? "",
      erpTicketType: source.source === "CSP" ? "ZINS" : "ZSRQ",
      modifiedBy: `${cspDetails?.firstName} ${cspDetails?.lastName}`,
      modifiedByEmail: cspDetails?.email || "",
      modifiedByEid: cspDetails?.Employee_ID || "",
      assignedTo:
        cspDetails?.source === "CSP"
          ? cspDetails?.CSP.length
            ? cspDetails?.CSP.filter((csp) => csp?.DefaultCSP === "1")[0].CSPOwner
            : null
          : cspDetails?.CASEDetails.length
          ? cspDetails?.CASEDetails.filter((cases) => cases.DefaultCase === "1")[0].CaseOwner
          : null,
      assignedToEmail:
        cspDetails?.source === "CSP"
          ? cspDetails?.CSP.length
            ? cspDetails?.CSP.filter((csp) => csp?.DefaultCSP === "1")[0].CSPOwnerEmail
            : null
          : cspDetails?.CASEDetails.length
          ? cspDetails?.CASEDetails.filter((cases) => cases.DefaultCase === "1")[0].CaseOwnerEmail
          : null,
      assignedToEid:
        cspDetails?.source === "CSP"
          ? cspDetails?.CSP.length
            ? cspDetails?.CSP.filter((csp) => csp?.DefaultCSP === "1")[0].CSPOwnerEId
            : null
          : cspDetails?.CASEDetails.length
          ? cspDetails?.CASEDetails.filter((cases) => cases.DefaultCase === "1")[0].CaseOwnerEId
          : null,
    };
    let missingFieldsArray = [];
    for (const key of Object.keys(payload)) {
      if (key === "workticketId" || key === "salesOrg") {
        if (payload[key] === "" || payload[key] === undefined || payload[key] === null) {
          missingFieldsArray.push(key);
        }
      }
    }
    if (missingFieldsArray.length > 0) {
      setIsCancelOptionModal(false);
      return setTimeout(() => {
        notify(
          "Unable to cancel work ticket due to missing information. Kindly contact IT team for support. Missing information: " +
            missingFieldsArray.toString() +
            ".",
          "error",
          false
        );
      }, 10);
    }
    setLoader(true);
    let response = await cancelWorkticket(payload);

    if (response.status === 200 && response?.data?.statusCode === "200") {
      if (response?.data?.message === "Success") {
        if (response?.data?.data?.code === "200") {
          notify(response?.data?.data?.messageDescription, "success");
          getWorkTicketList();
        } else {
          notify(response?.data?.data?.messageDescription, "error");
        }
      } else {
        notify(response?.data?.data?.messageDescription, "error");
      }
      setRejectReasonModalStatus(false);
      setTimeout(() => {
        notification.setNotification(null);
      }, 5000);
    } else {
      notify("We are currently experiencing issues, please try again", "error");
      // if (response?.data?.message !== "Success") {
      //   notify(response?.data?.data?.messageDescription, "error");
      // }
      setRejectReasonModalStatus(false);
      setTimeout(() => {
        notification.setNotification(null);
      }, 5000);
    }
    setLoader(false);
  };

  // const checkIsCancel = () => {
  //   const soNumbers = ticketDetails.materials.map((material) => {
  //     return { salesOrder: material.salesOrder };
  //   });
  //   if (soNumbers.some((sonum) => sonum.salesOrder !== null)) {
  //     setIsCancelTicket(false);
  //   }
  // };

  const redirectToAddJobPage = () => {
    let cspAccDetails = cspDetails?.CSP?.filter((cspDetail) => cspDetail.DefaultCSP === "1")[0] || "";
    let data = {
      workTicket: {
        ...ticketDetails,
        source: source.source,
        AccountID: cspDetails.AccountID,
        isC4SWorkTicket: true,
        appointmentLength: 0,
        startDate: moment(new Date()).add(2, "days").format("MMMM DD,YYYY"),
        id: id,
        userId,
        jobDetails: [
          {
            cspSfdcId: cspAccDetails?.CSPID || "",
            cspOwner: cspAccDetails?.CSPOwner || "",
            cspName: cspAccDetails?.CSPName || "",
            caseSfdcId: cspDetails?.CASEDetails[0]?.CaseSFDCID || "",
            caseOwner: cspDetails?.CASEDetails[0]?.CaseOwner || "",
            caseNumber: cspDetails?.CASEDetails[0]?.CaseNumber || "",
            workTickets: [
              {
                workTicket: ticketDetails.ticketId,
              },
            ],
          },
        ],
      },
      cspDetails: cspDetails,
    };
    switchTab(2, { data: data });
  };

  const showOverlay = () => {
    let overlay = document.getElementById("overlay_panel");
    overlay.removeAttribute("hidden");
    document.getElementById("listItem1").focus();
  };

  return (
    <>
      {rejectReasonModalStatus && (
        <RejectReasonModal
          rejectReasonModalStatus={rejectReasonModalStatus}
          setRejectReasonModalStatus={setRejectReasonModalStatus}
          handleCancelShipment={handleCancelShipment}
          notify={notify}
        />
      )}
      {isCancelOptionModal && (
        <Modal
          role="dialog"
          ariaLabel="Delete Item"
          opened={true}
          disableOutsideClick
          closeButton={
            <FlexRow justifyContent="space-between" onClick={(event) => event.preventDefault()}>
              <div css={{ paddingLeft: "0.7rem" }}>
                <Title size="medium">Cancel Ticket</Title>
              </div>
              <div style={{ marginRight: "1.3rem" }}>
                <div style={{ cursor: "pointer" }} onClick={() => setIsCancelOptionModal(false)}>
                  <Icon
                    aria-label="close"
                    alt="close-icon"
                    role="img"
                    name="close"
                    size="medium"
                    color="#000000"
                    style={{ cursor: "pointer", outline: "none" }}
                  />
                </div>
              </div>
            </FlexRow>
          }
        >
          <ModalBody css={{ padding: "16px 0 16px 16px !important" }}>
            <p>Are you sure you would like to cancel the ticket?</p>
            <Spacer top="1rem" />
            <Spacer bottom="10px" />
            <FlexRow>
              <CustomSLButton aria-label="Delete" role="button" css={{ padding: "0 10px 0 10px" }} primary onClick={handleCancelWorkTicket}>
                Close
              </CustomSLButton>
              <CustomSLButton aria-label="Cancel" role="button" secondary onClick={() => setIsCancelOptionModal(false)}>
                Cancel
              </CustomSLButton>
            </FlexRow>
          </ModalBody>
        </Modal>
      )}
      <Loader active={isLoading} fullscreen />
      <OverlayPanel tabIndex={0} ref={moreOperations} id="overlay_panel" onShow={showOverlay} aria-modal="true">
        {/* <p
          style={{
            cursor: ticketDetails?.status?.toLowerCase() === "error" ? "pointer" : "",
            marginTop: "0.5rem",
            marginBottom: "0rem",
            color: ticketDetails?.status?.toLowerCase() === "error" ? "#000" : "#d8dada",
            fontSize: "0.75rem",
            fontFamily: "Verizon-NHG-eTX,Helvetica,Ariel,Sans-serif",
          }}
          onClick={() => handleHamburgerActions("ReRelease")}
        >
          Re Release Ticket
        </p> */}
        <div role="listbox" tabIndex={0}>
          <Options
            style={{
              cursor:
                ticketDetails.installType === "VMI" &&
                (ticketDetails?.status?.toLowerCase() === "released" || ticketDetails?.status?.toLowerCase() === "shipped")
                  ? "pointer"
                  : "",
              marginTop: "0.5rem",
              marginBottom: "0rem",
              color:
                ticketDetails.installType === "VMI" &&
                (ticketDetails?.status?.toLowerCase() === "released" || ticketDetails?.status?.toLowerCase() === "shipped")
                  ? "#000"
                  : "#d8dada",
              fontSize: "0.75rem",
              fontFamily: "Verizon-NHG-eTX,Helvetica,Ariel,Sans-serif",
            }}
            onClick={() => handleHamburgerActions("Schedule")}
            role="option"
            tabIndex="0"
            aria-label="Schedule Appointment"
            aria-disabled={
              ticketDetails.installType === "VMI" &&
              (ticketDetails?.status?.toLowerCase() === "released" || ticketDetails?.status?.toLowerCase() === "shipped")
                ? false
                : true
            }
            id="listItem1"
            onKeyDown={(e) => {
              if (e.key === "ArrowDown") {
                document.getElementById("listItem2").focus();
              } else if (e.key === "Enter") {
                handleHamburgerActions("Schedule");
              }
            }}
          >
            Schedule Appointment
          </Options>
          {ticketDetails?.status?.toLowerCase() === "error" && (
            <Options
              style={{ cursor: "pointer", marginTop: "0.5rem" }}
              onClick={() => handleHamburgerActions("Cancel")}
              role="option"
              tabIndex="0"
              aria-label="Cancel Ticket"
              id="listItem2"
              onKeyDown={(e) => {
                if (e.key === "ArrowUp") {
                  document.getElementById("listItem1").focus();
                } else if (e.key === "Enter") {
                  handleHamburgerActions("Cancel");
                }
              }}
            >
              Cancel Ticket
            </Options>
          )}
          {(ticketDetails?.status?.toLowerCase() === "released" || ticketDetails?.status?.toLowerCase() === "shipped") &&
            ticketDetails?.allowCancel && (
              <Options
                style={{ cursor: "pointer", marginTop: "0.5rem" }}
                onClick={() => handleHamburgerActions("CancelShipment")}
                role="option"
                tabIndex="0"
                aria-label="Cancel Shipment"
                id="listItem2"
                onKeyDown={(e) => {
                  if (e.key === "ArrowUp") {
                    document.getElementById("listItem1").focus();
                  } else if (e.key === "Enter") {
                    handleHamburgerActions("CancelShipment");
                  }
                }}
              >
                Cancel Shipment
              </Options>
            )}
        </div>
      </OverlayPanel>
      <img
        style={{ width: "15px", cursor: "pointer" }}
        alt="more-icon"
        tabIndex={0}
        src={moreIcon}
        onClick={(e) => moreOperations.current.toggle(e)}
        aria-controls="overlay_panel"
      />
      {component}
    </>
  );
});
export default ThreeDotsTemplate;
